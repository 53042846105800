@import '../../theme/theme.module';

$page-content-padding-vertical: $theme-spacing * 3;

:global {
  body {
    font-size: $theme-font-size-base;
    cursor: default;
  }
}

.page {
  background-color: $brand-color-background-white;
  box-shadow: 0 0 30px $brand-color-secondary-grey-light;
}

.content {
  padding: $page-content-padding-vertical 0;
}
