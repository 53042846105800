@import '../../theme/theme.module';

$header-contents-height: 90px;
$header-padding-vertical: $theme-spacing * 2;

.header {
  height: $header-contents-height + ($header-padding-vertical * 2);
  padding: $header-padding-vertical 0;
  border-bottom: 1px solid $brand-color-secondary-grey-lightest;
}

.logo {
  height: $header-contents-height;
}
